export const Types = {

    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'USERS_LOGOUT',

    LOGIN_SUCCESS_CLOSE_MESSAGE: 'LOGIN_SUCCESS_CLOSE_MESSAGE',

    LOJA_SUCCESS: 'LOJA_SUCCESS',
    LOJA_EDIT: 'LOJA_EDIT',

    SHOW_MODAL: 'SHOW_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',

    PERFIL_SELECINADO_ADD: 'PERFIL_SELECINADO_ADD',
    PERFIL_SELECINADO_SHOW: 'PERFIL_SELECINADO_SHOW',

    ASSINATURA_SELECIONADA_ADD: 'ASSINATURA_SELECIONADA_ADD',
    ASSINATURA_SELECIONADA_SHOW: 'ASSINATURA_SELECIONADA_SHOW',

    HEADER_MENU_CLICKED: 'HEADER_MENU_CLICKED',

    SHOW_COTACAO_FRETE_LANDING_PAGE: 'SHOW_COTACAO_FRETE_LANDING_PAGE',
    BACK_TO_CALCULADORA_FRETE_LANDING_PAGE: 'BACK_TO_CALCULADORA_FRETE_LANDING_PAGE',

    SET_CARRINHO_INFORMACOES: "SET_CARRINHO_INFORMACOES",

    SET_FRETE_COMPRADO_COM_SUCESSO: "SET_FRETE_COMPRADO_COM_SUCESSO",

    SUBMIT_CALCULADORA_FORM: "SUBMIT_CALCULADORA_FORM",

    SET_FRETE_COTACOES_CARRINHO: "SET_FRETE_COTACOES_CARRINHO",

    SET_FRETE_COTACOES: "SET_FRETE_COTACOES",

    SET_FRETE_COTACAO_SELECIONADA: "SET_COTACAO_SELECIONADA",

    ENDERECO_EDITADO: "ENDERECO_EDITADO",

    SET_FRETE_COTACAO_CARRINHO_SELECIONADA: "SET_FRETE_COTACAO_CARRINHO_SELECIONADA",

    ADD_CALCULADORA_DADO_COTACAO: "ADD_CALCULADORA_DADO_COTACAO",

    ADD_TO_COTACOES_LIST: "ADD_TO_COTACOES_LIST",

    SET_LOJA: "SET_LOJA",

    SET_USAR_DADOS_DO_USUARIO: "SET_USAR_DADOS_DO_USUARIO",

    LAST_CONTA_FLEX: "LAST_CONTA_FLEX",

}

