import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {useHistory} from 'react-router-dom';
import UsuarioService from '../../services/UsuarioService';
import PerfilSelecao from '../../components/PerfilSelecao/PerfilSelecao'
import { PerfilFormStyle, PerfilContainerStyle, PerfilContentStyle} from "./style";
const usuarioService = new UsuarioService();

function Perfil() {
	const history = useHistory();
	const [listaDePerfis] = useState([]);
	const [processando, setProcessando] = useState(false);

	const user = useSelector((state) => state.user);
	const userUuid = user.uuid;

	useEffect(() => {
		if(!processando) {
			setProcessando(true);
			usuarioService.obterPerfisDeAcesso(userUuid, (erro, sucesso) => {
				if(erro){
					console.log(erro);
					return
				}
				if(sucesso){
					history.push("/home");
				}
			})
		}
	}, [processando, history, userUuid]);

	const perfis = listaDePerfis.map((item) => {
		return(
			<PerfilSelecao key={item.uuid} item={item} />
		)
	});

	return (
		<>
			<PerfilFormStyle>
				<PerfilContainerStyle>
					{
						!listaDePerfis.length ?
						(
							null
						) :
						(
						<PerfilContentStyle>
							<div className="perfil-div ">
								<span>Selecione o perfil que deseja acessar:</span>
								{perfis}
							</div>
						</PerfilContentStyle>
						)
					}
				</PerfilContainerStyle>
			</PerfilFormStyle>
		</>
	);
}

export default Perfil;
