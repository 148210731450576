import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ActionCreators } from "../../_actions/ActionCreators";
import TagueamentoService from "../../services/TagueamentoService";
import { moneyMask, cepMask, handleDecimalPlaces } from "../../utils/Masks";
import { CalculadoraResultadoLogadoStyle } from "./style";

const tagueamentoService = new TagueamentoService();

export default function CalculadoraResultadoLogado({
  cotacoes,
  setCotacoes,
  redirect,
  payload
}) {
  const dispatch = useDispatch();

  const getTipoTagLabel = (tipo) => {
    if (tipo === "MAIS_BARATO") {
      return ["+ BARATO"];
    } else if (tipo === "MAIS_RAPIDO") {
      return ["+ RÁPIDO"];
    } else if (tipo === "MAIS_BARATO_E_RAPIDO") {
      return ["+ BARATO", "+ RÁPIDO"];
    }
    return [];
  };

const CustomSeparator = () => {
	return <span className="custom-separator">|</span>;
};

const getFraseDiasUteis = (numeroDias) => {
	const frase = numeroDias === 1 ? 'dia útil' : 'dias úteis'
	return `${numeroDias} ${frase}`
}

const { cepOrigem, cepDestino, valorEncomenda, dimensoes } = payload[0];

  return (
		<CalculadoraResultadoLogadoStyle>

			<h1>Resultado da Cotação</h1>

			<div>
				<div className="resultado-header">
					<div className="header-alerta">
						{cotacoes[0]?.sobretaxada ? (
						<>
							<ion-icon name="alert-circle-outline"></ion-icon>
							<span>
							Seu produto foi sobretaxado
							</span>
							:
						</>
						) : (
						""
						)}
					</div>
				</div>

				<div className="conteudo-info">
                    <span >
                        Origem: {cepMask(cepOrigem)}
                        <CustomSeparator />
                        Destino: {cepMask(cepDestino)}
                        <CustomSeparator />
                        Valor da encomenda: {moneyMask(valorEncomenda.toFixed(2))}
                    </span>
                    <span>
						<CustomSeparator /> {dimensoes.alturaPacote} cm x {dimensoes.larguraPacote} cm x {dimensoes.comprimentoPacote} cm
                        <CustomSeparator />
                        Peso: {handleDecimalPlaces(dimensoes.pesoPacote, 3)?.replace(".", ",")} kg
                    </span>
                </div>

				<div className="cotacoes">

					{/* desktop */}
					<table className="table is-desktop">
						<thead>
							<tr>
								<th>TRANSPORTADORA</th>
								<th>MODALIDADE</th>
								<th style={{ whiteSpace: 'nowrap' }} >PRAZO ESTIMADO</th>
								<th>VALOR</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
						{
							cotacoes?.map((item,i) => {
								return (
									<tr key={i}>
										<td className="transportadora-table-img">
											<img src={`${item.transportadora.urlLogo}`}
											alt={`${item.transportadora.nomeTransportadora} logo`}
											className="transportadora-logo"/>
										</td>
										<td>
											{item.produto.nomeProduto}
										</td>
											{
												item.prazoDeEntrega.tempoMinimo === item.prazoDeEntrega.tempoMaximo ?
												(
													<td className="text-result-cot is-size-4-tablet is-size-5-mobile">{getFraseDiasUteis(item.prazoDeEntrega.tempoMinimo)}</td>
												) : (
													<td>{item.prazoDeEntrega.tempoMinimo} - {item.prazoDeEntrega.tempoMaximo} dias úteis</td>
												)
											}
										<td className="text-result-cot" style={{whiteSpace: 'nowrap', }}>
											<strong className="is-size-3 is-size-4-tablet is-size-5-mobile" style={{whiteSpace: 'nowrap' }}>{moneyMask(item.valorFrete.toFixed(2)) || "R$ 0,00"}</strong>
										</td>
										<td>
										<div className="selecionar-frete cotacao "
											onClick={() => {
												tagueamentoService.eventoMarketingItemSelecionado(
													item
												);
												dispatch(
													ActionCreators.setFreteCotacaoSelecionada(item)
												);
												redirect({dest: "editInfosComplementares"});
											}}>
											<button className="button selecionar">Selecionar</button>
										</div>
										</td>
									</tr>
								)

							})
						}
						</tbody>
						<tfoot >
							<td colspan="4" style={{paddingLeft: "0px"}}>
								<div className="suporte" style={{marginLeft: "0px"}}>
									<a href="https://suporte.flexfrete.com.br" target="_blank" rel="noreferrer" activeClassName="selected-item" >
										<span className="side-menu-title">Dúvidas sobre as cotações? <span style={{ fontWeight: '700' }}>Clique aqui</span> para mais informações.</span>
									</a>
								</div>
							</td>
						</tfoot>
					</table>


					{/* mobile */}
					{cotacoes?.map((cotacao,index) => {
						return (

							<>

								<div className="is-mobile-visibility contação-block">
									<div key={index+new Date()} className=" columns cotacao is-mobile is-align-items-start">
										<div className="transportadora-logo column is-one-third-mobile">
											{
												<>
													<img
														src={`${cotacao.transportadora.urlLogo}`}
														alt={cotacao.transportadora.nomeTransportadora}
													/>
												</>
											}

											{cotacao.tipoTag? (
												<div
													className="tag-qualidade"
													show={cotacao.tipoTag? true:false}
												>
													{getTipoTagLabel(cotacao.tipoTag).map((tag) => (
														<span className="tag-qualidade">Mais barato</span>
													))}
												</div>
											):(
												""
											)}
										</div>
										<div className="modalidade column is-one-third-mobile mr-5">
											<span className="modalidade-label">Modalidade</span>
											<span className="modalidade-value">
												{cotacao.produto.nomeProduto}
											</span>
										</div>
										<div className="modalidade column is-one-third-mobile">
											<span className="modalidade-label">Preço</span>
											<span className="modalidade-value has-text-weight-bold">
												{moneyMask(cotacao.valorFrete.toFixed(2))}
											</span>
										</div>

									</div>
									<div className="columns cotacao is-mobile is-align-items-start" >
										{/*<div className="column is-one-third is-offset-one-third mr-5">*/}
										{/*	<div className="modalidade flexfrete-economia-value">*/}
										{/*		<span className="modalidade-label has-text-grey-light">Economize*</span>*/}
										{/*		<span className="modalidade-value">*/}
										{/*			{moneyMask(*/}
										{/*				cotacao.valorEconomia<0*/}
										{/*					? (0).toFixed(2)*/}
										{/*					:cotacao.valorEconomia.toFixed(2)*/}
										{/*			)}*/}
										{/*		</span>*/}
										{/*	</div>*/}

										{/*</div>*/}
										<div className="column is-one-third">
											<div className="modalidade">
												<span className="modalidade-label">Prazo</span>
												<span className="modalidade-value has-text-left">{`${cotacao.prazoDeEntrega.tempoMaximo
													} ${cotacao.prazoDeEntrega.tempoMaximo>1
														? "dias úteis"
														:"dia útil"
													}`}</span>
											</div>
										</div>

									</div>
									<div
										className="selecionar-frete cotacao "
										onClick={() => {
											tagueamentoService.eventoMarketingItemSelecionado(
												cotacao
											);
											dispatch(
												ActionCreators.setFreteCotacaoSelecionada(cotacao)
											);
											redirect({dest: "editInfosComplementares"});
										}}
									>
										<button className="button selecionar">Selecionar</button>
									</div>
								</div>

							</>

						);
					})}
				</div>
				<div className="voltar-action">
					<Link
					to="/calculadora"
					onClick={() => {
						redirect("calculadora");
					}}
					className="button  is-primary"
					>
					Voltar
					</Link>
				</div>
			</div>
		</CalculadoraResultadoLogadoStyle>
	);
}
