import { configureStore } from '@reduxjs/toolkit';
import { Types } from '../_constants/Types';
import AssinaturaService from '../services/AssinaturaService';
import AutenticacaoService from '../services/AutenticacaoService';
import CalculadoraService from '../services/CalculadoraService';
import CarrinhoService from '../services/CarrinhoService';
import UsuarioService from '../services/UsuarioService';

const autenticacaoService = new AutenticacaoService();
const usuarioService = new UsuarioService();
const assinaturaService = new AssinaturaService();
const calculadoraService = new CalculadoraService();
const carrinhoService = new CarrinhoService();

const usuarioCorrente = autenticacaoService.usuarioCorrente() || {};
const enderecoCorrente = autenticacaoService.enderecoCorrente() || {};

const initialState = {
    user: {
        dateCreated: usuarioCorrente.dateCreated || "",
        name: usuarioCorrente.name ||  "",
        roles: usuarioCorrente.roles || [],
        timeToLive: usuarioCorrente.timeToLive || "",
        token: usuarioCorrente.token || "",
        username: usuarioCorrente.username || "",
        uuid: usuarioCorrente.uuid || "",
        identificadorAssinatura: usuarioCorrente.identificadorAssinatura || "",
        email: usuarioCorrente.email ||  "",
        endereco: usuarioCorrente.endereco
    },
    endereco: enderecoCorrente || {},

    isUserLogged: autenticacaoService.usuarioCorrente() ? true : false,
    isUserRegister: false,
    headerClicked: false,
    modal: {
        showModal: false,
    },
    perfilSelecionado: usuarioService.getPerfilSelecionadoCorente(),
    assinaturaSelecionada: assinaturaService.getAssinaturaSelecionada(),

    loja: {},

    freteCalcResultLandingPage: [],
    thereIsFreteCalcResultLandingPage: false,

    cotacaoSelecionada: {},
    cotacaoCarrinhoSelecionada: {},


    calculadoraFormInfo: calculadoraService.getCalculadoraFormInfo() || {},

    carrinhoInformacoes: carrinhoService.getCarrinhoInfosParaCompra() || [],

    freteCompradoComSucesso: {},

    freteCotacaoCarrinhoSelecionada: calculadoraService.getCotacaoCarrinhoSelecionada() || null,

    freteCotacaoSelecionada: null,

    freteCotacoes: [],

    freteCotacoesCarrinho: calculadoraService.getFreteCotacoes(),

    calculdadoraUltimasCotacoes: calculadoraService.getDadosUltimasCotacoes() || [],
    usarDadosUsuario: false,
    lastContaFlex: false,

}

function flexFreteReducer( state = initialState, action){
    switch(action.type){
        case Types.REGISTER_SUCCESS:
            return{
                ...state,
                isUserRegister: true,
            };
        case Types.LOGIN_SUCCESS:
            return{
                ...state,
                user: action.payload.user,
                isUserLogged: true
            };
        case Types.LOGIN_SUCCESS_CLOSE_MESSAGE:
            return{
                ...state,
                isUserRegister: action.payload.closeMessage
            }
        case Types.SET_LOJA:
            return{
                ...state,
                loja: action.payload.loja
            };
        case Types.SHOW_MODAL:
            return{
                ...state,
                modal: action.payload.modal,
            };
        case Types.HIDE_MODAL:
            return{
                ...state,
                modal: action.payload.modal,
        };
        case Types.HEADER_MENU_CLICKED:
            return{
                ...state,
                headerClicked: action.payload.headerClicked
            }
        case Types.ASSINATURA_SELECIONADA_ADD:
            return{
                    ...state,
                    assinaturaSelecionada: action.payload.assinatura
            }
        case Types.ENDERECO_EDITADO:
            return{
                    ...state,
                    endereco: action.payload.endereco
            }
        case Types.SHOW_COTACAO_FRETE_LANDING_PAGE:
            return{
                ...state,
                freteCalcResultLandingPage: action.payload.freteCalcResultLandingPage,
                thereIsFreteCalcResultLandingPage: true
            }
        case Types.BACK_TO_CALCULADORA_FRETE_LANDING_PAGE:
            return{
                ...state,
                freteCalcResultLandingPage: [],
                thereIsFreteCalcResultLandingPage: action.payload.backToCalculadoraLandingPage
            }

        case Types.SUBMIT_CALCULADORA_FORM:
            return{
                ...state,
                calculadoraFormInfo: action.payload.calculadoraFormInfo,
            }
        case Types.SET_CARRINHO_INFORMACOES:
            let novoItem = [...state.carrinhoInformacoes ?? []]
            novoItem.push(action.payload.carrinhoInformacoes);
            return{
                ...state,
                carrinhoInformacoes: novoItem,
            }
        case Types.SET_FRETE_COMPRADO_COM_SUCESSO:
            return{
                ...state,
                freteCompradoComSucesso: action.payload.freteCompradoComSucesso,
            }
        case Types.SET_FRETE_COTACOES_CARRINHO:
            return{
                ...state,
                freteCotacoesCarrinho: action.payload.freteCotacoesCarrinho,
            }
        case Types.SET_FRETE_COTACOES:
            return{
                ...state,
                freteCotacoes: action.payload.freteCotacoes,
            }
        case Types.SET_FRETE_COTACAO_SELECIONADA:
            return{
                ...state,
                freteCotacaoSelecionada: action.payload.freteCotacaoSelecionada,
            }
        case Types.SET_FRETE_COTACAO_CARRINHO_SELECIONADA:
            return{
                ...state,
                freteCotacaoCarrinhoSelecionada: action.payload.freteCotacaoCarrinhoSelecionada,
            }
        case Types.ADD_TO_COTACOES_LIST:
            let aux = [...state.calculdadoraUltimasCotacoes];
            const { dadosCalculadora, infosComplementares } = action.payload;

            aux.push({dadosCalculadora, infosComplementares});

            return{
                ...state,
               calculdadoraUltimasCotacoes: aux
        }
        case Types.ADD_CALCULADORA_DADO_COTACAO:
            return{
                ...state,
               dadosCalculadora: action.payload.dadosCalculadora
        }
        case Types.SET_USAR_DADOS_DO_USUARIO:
            return{
                ...state,
                usarDadosUsuario: action.payload.usarDadosDoUsuario
            }

        case Types.LAST_CONTA_FLEX:
            return {
                ...state,
                lastContaFlex: action.payload.lastContaFlex
            };

        default:
            return state;
    }

}

export default configureStore({
    reducer:flexFreteReducer
})
