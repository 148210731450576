import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from '../../_actions/ActionCreators';
import Input from '../../components/FlexFreteInput/Input';
import CalculadoraService from '../../services/CalculadoraService';
import GeografiaService from "../../services/GeografiaService";
import {cepMask, handleDecimalPeso} from '../../utils/Masks';
import Modal from "../FlexFreteModal/Modal";
import FlexSpinner from "../FlexFreteSpinner/FlexSpinner";
import { CalculadoraLogadoFormStyle } from './style';
import { moneyMask } from "../../utils/Masks";
import { SectionFormStyle } from "../FreteCalcForm/style";
import CurrencyInput from 'react-currency-masked-input';
import LojaService from "../../services/LojaService";

const calculadoraService = new CalculadoraService();
const geografiaService = new GeografiaService();
const lojaService = new LojaService();

const VALOR_MAXIMO_FRETE = Number(process.env.REACT_APP_VALOR_MAXIMO_FRETE)
const VALOR_MAXIMO_FRETE_MSG = `Este valor deve ser de até ${moneyMask(VALOR_MAXIMO_FRETE.toString())}.`
const VALOR_MAXIMO_DIMENSAO = Number(process.env.REACT_APP_VALOR_MAXIMO_DIMENSAO)

const cotacacaoBase = {
    "cepOrigem": "",
    "cepDestino": "",
    "isPacotePadrao": false,
    "avisoRecebimento":false,
    "dimensoes": {
        "alturaPacote": "",
        "larguraPacote": "",
        "comprimentoPacote": "",
        "pesoPacote": "",
        "diametroPacote": ""
    },
    "formato": "CAIXA",
    "valorEncomenda": "",
    "logisticaReversa": false
}

export default function CalculadoraLogadoForm({redirect, setEditInfoComplementaresDados}){
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [errorRequest, setErrorRequest] = useState(false);
    const [isWarningVolumes, setIsWarningVolumes] = useState(false);
    const [invalidValue, setInvalidValue] = useState(false);
    const [showingVolume, setShowingVolume] = useState(0);
    const [isCepValid, setIsCepValid] = useState({cepOrigem: true, cepDestino: true});
    const [erro, setErro] = useState({
        isWrong: false,
        titulo: "",
        mensagem: "",
        voltar: false,
      });
    const [cotacoes, setCotacoes] = useState(
        [
            {
                ...cotacacaoBase
            }
        ]
    )

    const [historicoCotacoes, setHistoricoCotacoes] = useState([])

    let state = useSelector((state) => state);

	const lojaUuid = (state && state.loja && state.loja?.uuid) ? state.loja?.uuid : null;

    const loja = useSelector((state) => state.loja);

    const {user} = state;

    const [request, setRequest] = useState({
        "uuidLoja": lojaUuid,
        "cotacoes": [
           {...cotacacaoBase}
        ]
    });

    useEffect(() => {
        calculadoraService.obtemUltimasCotacoes((_erroUltimasCotacoes, sucesso) => {
            if(sucesso){
                setHistoricoCotacoes(sucesso)
            }
        })
        setLoja()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setLoja = () => {
        if(loja && !loja.uuid){
            //buscar no localstorage
            const loja = JSON.parse(localStorage.getItem('loja'));
            if(loja){
                dispatch(ActionCreators.setLoja(loja));
            }
            if(!loja?.uuid){
                lojaService.obterLojas((err, loja) => {
                    if(err){
                        return;
                    }
                    if (loja?.length > 0) {
                        dispatch(ActionCreators.setLoja(loja[0]));
                        console.log('loja', loja[0]);
                      }

                });
            }

        }
    }


    useEffect(() => {
       const cepLoja = loja?.endereco?.cep;
       const {cep} = user.endereco;
       const cepEnderecoOrigem = loja?.ativo ? cepLoja : cep;

        setRequest((prevState) => ({
            ...prevState,
            cotacoes: [
            {
                "cepOrigem": cepEnderecoOrigem,
                "cepDestino": "",
                "isPacotePadrao": false,
                "avisoRecebimento":false,
                "dimensoes": {
                "alturaPacote": "",
                "larguraPacote": "",
                "comprimentoPacote": "",
                "pesoPacote": "",
                "diametroPacote": ""
                },
                "formato": "CAIXA",
                "valorEncomenda": "",
                "logisticaReversa": false
            }
            ]
        }));
    },[user.endereco, loja]);

    useEffect(() => {
       setCotacoes(request.cotacoes);
       if(isWarningVolumes){
           setIsWarningVolumes(false);
       }
    }, [request, isWarningVolumes])

    const clearForm = () =>{
        setShowingVolume(0);
        setUtilizarDadosUltimasCotacoes('');
        dispatch(ActionCreators.submitCalculoFrete({}));
        calculadoraService.deleteCalculadoraFormInfo();
        setRequest({
            "uuidLoja": lojaUuid,
            "cotacoes": [
                {
                    ...cotacacaoBase
                }
            ]
        })
    }

    const checkInvalidVolumes = () => {
        let hasInvalidFields = undefined;

        return cotacoes.forEach( (cotacao, index)=>{

            const {cepOrigem, cepDestino} = cotacao;

            if(!cepOrigem.length || !cepDestino.length){
                hasInvalidFields = {volume: index + 1, message: "Por favor, preencha corretamente as informações de origem e destino do volume "+ (index + 1)};
            }
            return hasInvalidFields;
        });

    }

    const submit = (e)=>{
        const pesoPacoteFloat = parseFloat(request.cotacoes[0].dimensoes.pesoPacote.toString().replace(',', '.'));
        e.preventDefault();
        const error = checkInvalidVolumes()
        if(error){
            setIsWarningVolumes(error.message);
            return;
        }

        localStorage.setItem('valorEncomenda', request.cotacoes[0].valorEncomenda);
        if(pesoPacoteFloat > 30){
            setErro({
                isWrong: true,
                mensagem: 'Somente podem ser contratados fretes com peso até 30kg.'
              })
            return;
        }
        if(request.cotacoes[0].dimensoes.alturaPacote > VALOR_MAXIMO_DIMENSAO || request.cotacoes[0].dimensoes.larguraPacote > VALOR_MAXIMO_DIMENSAO ||
            request.cotacoes[0].dimensoes.comprimentoPacote > VALOR_MAXIMO_DIMENSAO || request.cotacoes[0].dimensoes.diametroPacote > VALOR_MAXIMO_DIMENSAO){
            setErro({
                isWrong: true,
                mensagem: `Somente podem ser contratados fretes com dimensão até ${VALOR_MAXIMO_DIMENSAO}cm.`
              })
            return;
        }
        let somarDimensoes = 0;
        somarDimensoes = Number(request.cotacoes[0].dimensoes.alturaPacote) + Number(request.cotacoes[0].dimensoes.larguraPacote)  +
        Number(request.cotacoes[0].dimensoes.comprimentoPacote) + Number(request.cotacoes[0].dimensoes.diametroPacote);
        if(somarDimensoes > 240){
            setErro({
                isWrong: true,
                mensagem: 'A soma das dimensões não pode ultrapassar 240cm.'
              })
            return;
        }
        setIsLoading(true);
        let requestEnviar = JSON.parse(JSON.stringify(request));
        const cotacoesAux = requestEnviar.cotacoes;
        cotacoesAux[showingVolume]["dimensoes"]["pesoPacote"] = pesoPacoteFloat;
        requestEnviar = {...requestEnviar, cotacoes: cotacoesAux}
        calculadoraService.calcularFrete(requestEnviar, (err, sucess) =>{
            setIsLoading(false);
            if(err){
                setErrorRequest("Erro ao processar a requisição.");
                setTimeout(() => {
                    setErrorRequest(undefined);
                }, 3000);
            }else{
                const data = [...sucess];
                dispatch(ActionCreators.setFreteCotacoes(data));
                dispatch(ActionCreators.submitCalculoFrete({...requestEnviar}));
                dispatch(ActionCreators.addCalculadoraDadoCotacao({...requestEnviar}));
                setErrorRequest(false);
                redirect({dest: "cotacoes", payload: data});
            }
        });
    }
    const checkCepField = (cep, cepName) => {
		geografiaService.buscaPorCEP(cep, (erroCep, sucesso) => {
		  if(erroCep){
			  setIsCepValid({
                  ...isCepValid, [cepName]: false
              })
		  }else{
            setIsCepValid({
                ...isCepValid, [cepName]: true
            });
          }
		})
	}

   	const onBlur = (valor, cepName) =>{
		let cep = valor
		if(!cep.length){
		  return
		}
		let formatedcep = cep.replace(/[\D]/g, "");
	    checkCepField(formatedcep, cepName);
	};

    const callbackModal = () =>{
        if(erro.isWrong === true){
          setErro({isWrong: false})
        }
    }

    function showAvisoValorEncomenda() {
        setInvalidValue(true)
        setErro({
            isWrong: true,
            mensagem: VALOR_MAXIMO_FRETE_MSG
            })
        return;
    }

    const handleKeyPress = (e) => {
		if (e.key === '.' || e.key === ',') {
		  e.preventDefault();
		}
	};

    const [utilizarDadosUltimasCotacoes, setUtilizarDadosUltimasCotacoes] = useState('')
    const onChangeUtilizarDados = (e) => {
        const {value} = e.target;
        if(value === "") {
            setUtilizarDadosUltimasCotacoes('');
            setEditInfoComplementaresDados(undefined);
            setRequest({...request, cotacoes:[ {...cotacacaoBase} ]})
            return;
        }
        setUtilizarDadosUltimasCotacoes(value);
        const dadosCalculadora = JSON.parse(JSON.stringify(historicoCotacoes[value]))
        dadosCalculadora.dimensoes.pesoPacote = converterString(dadosCalculadora.dimensoes.pesoPacote)
        dadosCalculadora.valorEncomenda = validatedValue(dadosCalculadora.valorEncomenda)
        setRequest({...request, cotacoes:[dadosCalculadora]})
    }

    const validatedValue = (value) => {
        if (typeof value === 'number') {
            return  value.toFixed(2);
        }
        return value;
    }

/*     const onUsarDadosUsuario = (e) => {
        const { checked } = e.target;
        const cepOrigemValue = checked ? user.endereco?.cep : loja?.endereco?.cep;

        //setar o estado do usarDadosUsuario

        dispatch(ActionCreators.setUsarDadosDoUsuario(checked));

        setRequest((prevState) => {
            const updatedCotacoes = [...prevState.cotacoes]; // Copia o array existente

            if (updatedCotacoes.length > 0) {
                // Atualiza apenas o primeiro elemento
                updatedCotacoes[0] = {
                    ...updatedCotacoes[0],
                    cepOrigem: cepOrigemValue,
                };
            } else {
                // Se o array estiver vazio, adiciona um novo objeto
                updatedCotacoes.push({ cepOrigem: cepOrigemValue });
            }

            return {
                ...prevState,
                cotacoes: updatedCotacoes, // Define o array atualizado
            };
        });
    };
 */


    const converterString = (value) => value && value.toString().replace(".", ",")
    return (
        <CalculadoraLogadoFormStyle onSubmit={submit}>
            {isLoading? <FlexSpinner/>:""}
            {
            erro.isWrong === true ?
            (
                <Modal callbackModal={callbackModal} visivel={erro !== null} titulo={erro.titulo} conteudo={erro.mensagem} />
            ) : null
            }
            <div>
                <h2 className="form-title page-title">Dados da Cotação</h2>
            </div>

            <div className="form-container">
                <div className="cotacoes-ultimas">
                    <div className="select is-large">
                        <select onChange={onChangeUtilizarDados} value={utilizarDadosUltimasCotacoes}>
                            <option value="">Utilizar dados das últimas cotações</option>

                            {
                                historicoCotacoes?.map( (cotacao, index) => {
                                    const cot = cotacao;

                                    if(!cot){
                                        return '';
                                    }
                                    return (
                                    <option key={cot.uuid} value={index}>Origem: {cepMask(cot.cepOrigem)} Destino:  {cepMask(cot.cepDestino)} Valor da encomenda:
                                    {moneyMask(cot.valorEncomenda.toFixed(2))} {cot.dimensoes.comprimentoPacote}cmX{cot.dimensoes.larguraPacote}cmX{cot.dimensoes.alturaPacote}cm {converterString(cot.dimensoes.pesoPacote)}Kg</option>

                                        )
                                    }
                                )
                            }

                    </select>
                    </div>
                </div>

                <SectionFormStyle>
                    <div class="field is-grouped">
                        <div className="field padding">
                            <label htmlFor="cepOrigem" className="label " >CEP de origem</label>
                            <div className="control">
                                <Input className="input"
                                    type="text" maxLength="9" placeholder="00000-000" required={true} name="cepOrigem"
                                    mascara={"99999-999"} value={request.cotacoes[showingVolume]["cepOrigem"]}
                                    onChange={e => {
                                        const cotacoesAux = request.cotacoes;
                                        cotacoesAux[showingVolume]["cepOrigem"] = e.target.value.replace(/[\D]/g, "");
                                        setRequest({...request, cotacoes: cotacoesAux})
                                    }}
                                    onBlur={(e) => { onBlur(e.target.value, "cepOrigem")}}
                                />
                            </div>
                            {(!request.cepOrigem && erro.isWrong) && (
                                <p className="help is-danger" style={{ fontSize: '11.3px' }}>Preenchimento obrigatório</p>
                            )}
                            {
                                !isCepValid.cepOrigem? (
                                    <div className="notification is-warning">
                                        <p>Cep inválido</p>
                                    </div>
                                ):""
                            }
                        </div>
                        <div className="field padding">
                            <label htmlFor="cepDestino" className="label " >CEP de destino</label>
                            <div className="control">
                                <Input className="input"
                                    type="text" maxLength="9" placeholder="00000-000" required={true} name="cepOrigem"
                                    mascara={"99999-999"} value={request.cotacoes[showingVolume]["cepDestino"]}
                                    onChange={e => {
                                        const cotacoesAux = request.cotacoes;
                                        cotacoesAux[showingVolume]["cepDestino"] = e.target.value.replace(/[\D]/g, "");
                                        setRequest({...request, cotacoes: cotacoesAux})
                                    }}
                                    onBlur={(e) => { onBlur(e.target.value, "cepDestino")}}
                                />
                            </div>
                            {(!request.cepDestino && erro.isWrong) && (
                                <p className="help is-danger" style={{ fontSize: '11.3px' }}>Preenchimento obrigatório</p>
                            )}
                            {
                                !isCepValid.cepDestino? (
                                    <div className="notification is-warning">
                                        <p>Cep inválido</p>
                                    </div>
                                ):""
                            }
                        </div>
                        <div className="field padding" >
                            <label className="label  " htmlFor="valor">
                                Valor da encomenda (R$)
                            </label>
                            <div className="control">
                                <CurrencyInput
                                    name="valorEncomenda"
                                    placeholder="100,00"
                                    min="0"
                                    required={true}
                                    value={request.cotacoes[showingVolume].valorEncomenda}
                                    className="input" separator="."
                                    onChange={(e, valorNovo) => {
                                        const cotacoesAux = request.cotacoes;
                                        cotacoesAux[showingVolume]["valorEncomenda"] = valorNovo;
                                        setRequest({...request, cotacoes: cotacoesAux});
                                    }}
                                    onBlur={ () => {
                                        setInvalidValue(false);
                                        if(request.cotacoes[showingVolume].valorEncomenda > VALOR_MAXIMO_FRETE){
                                            showAvisoValorEncomenda()
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className="field padding">
                            <label className="label " htmlFor="formato">Tipo de pacote</label>
                            <div className="select opcoesTransacoes">
                                <select name="formato" className="selectWidth"
                                onChange={e => {
                                    const cotacoesAux = request.cotacoes;
                                    cotacoesAux[showingVolume]["formato"] = e.target.value;
                                    setRequest({...request, cotacoes: cotacoesAux})
                                }}>
                                    <option value="CAIXA" defaultValue>Caixa/Pacote</option>
                                    <option value="ENVELOPE">Envelope</option>
                                    <option value="ROLO_CILINDRO">Rolo/Cilindro</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </SectionFormStyle>

                <SectionFormStyle>
                    <div class="field is-grouped">
                        <Input
                            classField="padding"
                            type="text"
                            name="alturaPacote"
                            required={true}
                            value={ request.cotacoes[showingVolume].dimensoes.alturaPacote }
                            label="Altura (cm)"
                            placeholder="2"
                            onKeyPress={handleKeyPress}
                            onChange={e => {
                                const cotacoesAux = request.cotacoes;
                                cotacoesAux[showingVolume]["dimensoes"]["alturaPacote"] = parseInt(e.target.value.replace(/[^\d]/g, "")) || "";
                                setRequest({...request, cotacoes: cotacoesAux})
                            }}
                        />

                        <Input
                            classField="padding"
                            type="text"
                            name="larguraPacote"
                            required={true}
                            value={ request.cotacoes[showingVolume].dimensoes.larguraPacote }
                            label="Largura (cm)"
                            placeholder="12"
                            onKeyPress={handleKeyPress}
                            onChange={e => {
                                const cotacoesAux = request.cotacoes;
                                cotacoesAux[showingVolume]["dimensoes"]["larguraPacote"] = parseInt(e.target.value.replace(/[^\d]/g, "")) || "";
                                setRequest({...request, cotacoes: cotacoesAux})
                            }}
                        />

                        <Input
                            classField="padding"
                            id="comprimentoPacote"
                            value={ request.cotacoes[showingVolume].dimensoes.comprimentoPacote }
                            type="text"
                            name="comprimentoPacote"
                            label="Comprimento (cm)"
                            placeholder="17"
                            required={true}
                            onKeyPress={handleKeyPress}
                            onChange={e => {
                                const cotacoesAux = request.cotacoes;
                                cotacoesAux[showingVolume]["dimensoes"]["comprimentoPacote"] = parseInt(e.target.value.replace(/[^\d]/g, "")) || "";
                                setRequest({...request, cotacoes: cotacoesAux})
                            }}
                        />


                        <Input
                            classField="padding"
                            type="text"
                            name="pesoPacote"
                            label="Peso (Kg)"
                            required={true}
                            placeholder="0,3"
                            value={request.cotacoes[showingVolume].dimensoes.pesoPacote}
                            onChange= {e => {
                                const {value} = e.target;
                                const cotacoesAux = request.cotacoes;
                                cotacoesAux[showingVolume]["dimensoes"]["pesoPacote"] = handleDecimalPeso(value, 3);
                                setRequest({...request, cotacoes: cotacoesAux})
                            }}
                        />
                    </div>
{/*                     {
                        loja?.ativo && (
                            <div className="column field padding mb-4">
                                <div className="control is-flex">
                                <label className="checkbox ffl-custom-checkbox">
                                    <input
                                    type="checkbox"
                                    name="ativo"
                                    onChange={onUsarDadosUsuario}
                                    />
                                    &nbsp; <strong>Usar dados da Conta Flex</strong>
                                </label>
                                </div>
                            </div>
                        )
                    } */}

                </SectionFormStyle>

                <div className="actions">
                    <button className="button button-submit" type="submit" disabled={isLoading || !isCepValid.cepOrigem || !isCepValid.cepDestino || invalidValue} >Calcular</button>
                    <input type="button" className="button " onClick={clearForm} value="Limpar"/>
                </div>
                {
                    errorRequest || isWarningVolumes? (
                        <div className={`notification error-request ${errorRequest? "is-danger": "is-warning"} `}>
                            <p>{errorRequest || isWarningVolumes}</p>
                        </div>
                    ):""
                }
            </div>
        </CalculadoraLogadoFormStyle>
    )
}
