import axios from 'axios';

export default class GeografiaService {


        obtemMunicipios(uf, callback){
            axios.get(this.host() + '/api/v1/municipio?uf=' + uf + "&pageSize=10000000000")
                .then((response)=>{
                    callback(null, response.data)
                })
                .catch((err)=>{
                    callback(err, null)
                })
        }

        obtemEstados(callback){
            axios.get(this.host() + '/api/v1/uf')
                .then((response)=>{
                    callback(null, response.data)
                })
                .catch((err)=>{
                    callback(err, null)
                })
        }

        buscaPorCEP(cep, callback){
            axios.get(this.host() + '/api/v1/cep/' + cep)
            .then((response)=>{
                callback(null, response.data)
            })
            .catch((err)=>{
                callback(err, null)
            })
        }

    host() {
		return process.env.REACT_APP_HOST_API;
	}
}
